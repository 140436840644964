import React, { memo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Balance from '../../Header/Balance'
import cx from 'classnames'
import st from 'scss/Mobile.module.scss'
import { setNavOpen } from '../../../store/actions/mobileAction'
import { useNavigate } from 'react-router-dom'
import { callAuthWindow, callPostMessage } from '../../../helpers/helpers'
import { PMEndpoints } from '../../../store/types'
import { useTranslation } from 'react-i18next'
import promo from '../../../api/promo'
import PromoBanner from '../../PromoBanner/PromoBanner'

const Header = memo(() => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const auth = useSelector((state) => state?.userdata.balance)
	const balance = useSelector((state) => state?.userdata.balance, shallowEqual)
	const { t } = useTranslation()

	return (
		<header>
			<div
				className={st.hamburger}
				onClick={() => dispatch(setNavOpen(true))}
			/>
			{promo.enabled ? (
				balance.virtual <= 0.1 && (
					<span className={st.logo} onClick={() => navigate('')} />
				)
			) : (
				<span className={st.logo} onClick={() => navigate('')} />
			)}

			<PromoBanner device={'mobile'} />

			{auth ? (
				<div className={cx(st.userPanel, st.authorized)}>
					{balance.virtual > 0.1 && (
						<Balance
							title={t('translation:common.freespin')}
							value={balance?.virtual}
							device={'mob'}
							type="virtual"
						/>
					)}
					<Balance
						title={t('translation:common.balance')}
						value={balance?.real}
						device={'mob'}
					/>

					<div
						className={st.btnCashier}
						onClick={(event) => {
							event.preventDefault()
							callPostMessage(PMEndpoints.CASHIER)
						}}
					/>
				</div>
			) : (
				<div className={cx(st.userPanel, st.login)}>
					<div className={st.btnAuth} onClick={() => callAuthWindow()}>
						{t('translation:common.authorization')}
					</div>
				</div>
			)}
		</header>
	)
})
export default Header
