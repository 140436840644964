import { memo, useEffect, useMemo, useRef, useState } from 'react'
import promo from '../../api/promo'
import cx from 'classnames'
import st from './PromoBanner.module.scss'
import PropTypes from 'prop-types'

const PromoBanner = memo(({ device }) => {
	// display multiple banners
	const [bannerIndex, setBannerIndex] = useState(0)
	const intervalRef = useRef(null)
	const promoItem = promo.banners[bannerIndex]

	useEffect(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current)
		}
		intervalRef.current = setInterval(() => {
			setBannerIndex((prevState) => {
				let newIndex = prevState + 1

				// rotate banner index
				if (newIndex > promo.banners.length - 1) {
					newIndex = 0
				}

				return newIndex
			})
		}, promo.interval)

		// cleanup
		return () => {
			clearInterval(intervalRef.current)
		}
	}, [])

	// do not render banner if promo is disabled
	if (!promo.enabled) return null

	return (
		<a
			href={promoItem.url}
			className={cx(st.promoBanner, {
				[st.animated]: promoItem.animated,
				[st.mobile]: device === 'mobile',
			})}
			style={{
				backgroundImage: `url(/img/promo/${promoItem.id}.png)`,
			}}
			target={'_blank'}
			rel={'noreferrer'}
		>
			Volt promo
		</a>
	)
})

PromoBanner.displayName = 'PromoBanner'
PromoBanner.propTypes = {
	device: PropTypes.oneOf(['mobile', 'desktop']),
}
PromoBanner.defaultProps = {
	device: 'desktop',
}

export default PromoBanner
